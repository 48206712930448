.sell-home-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    .header-top-section {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 70px;
        background-color: #fff;
      }
    
      .header-header-text {
        font-weight: bold;
        height: auto;
        width: 70%;
        font-size: 30px;
        display: flex;
        padding-left: 60px;
        align-items: center;
      }
    
      .header-header-links {
        display: flex;
        justify-content: space-around;
        width: 35%;
        align-items: center;
        font-size: calc(8px + 6 * ((100vw - 320px) / 680));
      }
      .header-link {
        color: #64ac2f;
        cursor: pointer;
        height: 32  px;
        width: 28%;
        border: 2px solid rgba(100, 172, 47, 0.3);
        border-radius: 5px;
        display:flex;
        justify-content: center;
        align-items: center;
      }
    
      .header-link:hover {
        border: transparent;
        color: #fff;
        background: #64ac2f;
        transition: all 0.25s ease-in;
      }
}