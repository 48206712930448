.App-wrapper {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  overflow: hidden;
}
